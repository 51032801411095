





























































































































































































































































































































































































import Vue from 'vue'
import { Contact, ApiResponse, Inventory, Requisition } from '@/interfaces'
import { mapState } from 'vuex'
import { ElForm } from 'element-ui/types/form'
import { countries } from '@/formHelpers'

interface Address {
  street: string
  postcode: string
  city: string
  label: string
}

interface TargetObject {
  value: string
  label: string
}

interface CategoryObject {
  value: string
  label: string
}

interface CivilityObject {
  value: string
  label: string
}

export default Vue.extend({
  props: {
    showFormModal: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object as () => Contact,
      required: false,
      default: () => {
        return {
          category: null,
        }
      },
    },
    inventory: {
      type: String,
      required: false,
      default: null,
    },
    requisition: {
      type: String,
      required: false,
      default: null,
    },
    fetchContactId: {
      type: String,
      required: false,
      default: null,
    },
    actionType: {
      type: String,
      required: false,
      default: null,
    },
    infoPrefill: {
      type: String,
      required: false,
      default: null,
    },
    saveContactTrigger: Boolean,
    isDisabled: Boolean,
    resetForm: Boolean,
    usedContact: Boolean,
  },
  data() {
    return {
      busy: this.isDisabled,
      contactData: {
        category: null,
        additionalEmails: null as string[] | null,
      } as Contact,
      categories: null as CategoryObject[] | null,
      countries,
      formModel: {},
      formRules: {},
      viewportWidth: 0,
      alreadyUsedContact: false,
      billingAddressCheckbox: false,
      displayTooltip: false,
      displayCategoryTooltip: false,
      changeDetected: null as null | boolean,
      addressSearch: '',
      billingAddressSearch: '',
      addresses: [] as Address[],
      autocompleteNone: 'none',
      additionalEmail: '',
      userTargets: null as TargetObject[] | null,
      civilities: null as CivilityObject[] | null,
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
    contactName(): string {
      if (this.contactData?.type === 'personne_morale') {
        return this.contactData?.companyName ?? ''
      } else if (this.contactData?.type === 'personne_physique') {
        let formName = ''
        if (this.contactData?.lastName) {
          formName += this.contactData?.lastName
        }
        if (this.contactData?.firstName) {
          formName += ' ' + this.contactData?.firstName
        }
        if (this.contactData?.contactNumber) {
          formName += ' #' + this.contactData?.contactNumber
        }
        return formName
      }
      return ''
    },
  },
  watch: {
    user(newVal) {
      this.userTargets = newVal.client.config.targets as TargetObject[]
      this.categories = newVal.client.config.categories as CategoryObject[]
      this.civilities = newVal.client.config.civilities as CivilityObject[]
      this.addons = newVal.client?.addons ?? 0
    },
    showFormModal() {
      // This is used as a trick to reset the form data every time the form modal is shown,
      // as well as for the cancel button, so that it doesn't automatically redirect the user to the contact list
      this.resetFormData()
    },
    triggerSave() {
      if (this.changeDetected === true) {
        //this.submit()
      }
    },
    fetchContactId: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (Number.isInteger(parseInt(newVal)) && parseInt(newVal) !== 0) {
          this.getContact(newVal)
        } else {
          this.resetFormData()
        }
      },
    },
    usedContact: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.alreadyUsedContact = newVal
      },
    },
    contactData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.changeDetected === false) {
          this.changeDetected = true
        }
      },
    },
    resetForm() {
      this.resetFormData()
    },
    saveContactTrigger() {
      this.submit()
    },
    infoPrefill: {
      immediate: true,
      handler(newVal) {
        this.contactData = {
          ...this.contactData,
          lastName: newVal,
        }
      },
    },
    billingAddressCheckbox(newVal) {
      if (newVal === false) {
        this.contactData.billingAddress = null
        this.contactData.billingAddress2 = null
        this.contactData.billingCity = null
        this.contactData.billingCountry = null
        this.contactData.billingPostalCode = null
      }
    },
  },
  mounted() {
    if (Number.isInteger(parseInt(this.fetchContactId)) && parseInt(this.fetchContactId) !== 0) {
      this.getContact(this.fetchContactId)
    } else {
      this.resetFormData()
    }

    if (this.user) {
      this.userTargets = this.user.client.config.targets as TargetObject[]
      this.categories = this.user.client.config.categories as CategoryObject[]
      this.civilities = this.user.client.config.civilities as CivilityObject[]
      this.addons = this.user.client?.addons ?? 0
    }

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)

    this.$nextTick(() => {
      this.changeDetected = false
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    setAttribute() {
      this.autocompleteNone = 'none'
    },
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    resetFormData() {
      this.contactData = {
        type: 'personne_physique',
        category: null,
        country: null,
        billingCountry: null,
        subjectToTax: false,
        subjectToTaxBuyer: true,
        lastName: this.infoPrefill,
        additionalEmails: [],
      }
      this.addressSearch = ''
      this.billingAddressSearch = ''
    },
    cancelForm() {
      if (this.showFormModal) {
        this.$emit('closeModal')
        this.addressSearch = ''
        this.billingAddressSearch = ''
      } else {
        this.$router.push({ name: 'contacts' })
      }
    },
    getContact(contactId: string) {
      this.busy = true
      this.$api
        .get(`/contact/${contactId}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$emit('updateUsedContact', false)
          if (this.inventory) {
            apiResponse.data.inventoriesAsDebiteur.forEach((inv: Inventory) => {
              if (inv.id?.toString() !== this.inventory) {
                this.$emit('updateUsedContact', true)
              }
            })
          }
          delete apiResponse.data.inventoriesAsDebiteur // To avoid error with the API when update (ORM try de update inventoriesAsDebiteur)

          if (this.requisition) {
            apiResponse.data.requisitionsAsDebiteur.forEach((req: Requisition) => {
              if (req.id?.toString() !== this.requisition) {
                this.$emit('updateUsedContact', true)
              }
            })
          }
          delete apiResponse.data.requisitionsAsDebiteur

          this.contactData = apiResponse.data
          this.$emit('updateContactInfos', apiResponse.data)

          if (
            this.contactData.billingAddress !== null ||
            this.contactData.billingAddress2 !== null ||
            this.contactData.billingCity !== null ||
            this.contactData.billingCountry !== null ||
            this.contactData.billingPostalCode !== null
          ) {
            this.billingAddressCheckbox = true
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
          this.changeDetected = false
        })
    },
    reset() {
      ; (this.$refs.myForm as ElForm).resetFields()
    },
    /*
  updateSubjectToTax(contactType: string) {
    if (contactType === 'personne_physique') {
      this.contactData.subjectToTax = false
    } else if (contactType === 'personne_morale') {
      this.contactData.subjectToTax = true
    }
  },
  */
    submit() {
      if (this.changeDetected === true) {
        this.displayTooltip = false
        this.displayCategoryTooltip = false

        // Checking if the contact category has been chosen
        if (!this.contactData.type) {
          this.displayCategoryTooltip = true

          return false
        }

        // Checking if at least the contact name (or company name) is entered
        if (
          (this.contactData.type == 'personne_morale' &&
            (this.contactData.companyName == '' || this.contactData.companyName == null)) ||
          (this.contactData.type == 'personne_physique' &&
            (this.contactData.lastName == '' || this.contactData.lastName == null))
        ) {
          this.displayTooltip = true

          return false
        }

        ; (this.$refs.myForm as ElForm).clearValidate(['email'])

        this.busy = true
          ; (this.$refs.myForm as ElForm).validate((valid: boolean) => {
            if (valid) {
              const data = {
                ...this.contactData,
              }

              if (!this.contactData.id) {
                // Store
                this.$api
                  .post('/contact', data)
                  .then((response) => {
                    const apiResponse = response.data as ApiResponse

                    this.contactData = apiResponse.data
                    this.$notify({
                      type: 'success',
                      title: 'Succès',
                      message: 'Contact créé avec succès !',
                    })
                    this.$emit('contactCreated', apiResponse.data)
                  })
                  .catch((error) => {
                    if (error.response) {
                      const apiResponse = error.response.data as ApiResponse

                      this.$notify({
                        type: 'error',
                        title: 'Erreur',
                        message:
                          apiResponse.message ??
                          'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                      })
                    }
                  })
                  .finally(() => {
                    this.busy = false
                    this.changeDetected = false
                    this.addressSearch = ''
                    this.billingAddressSearch = ''
                  })
              } else {
                // Edit
                const loading = this.$loading({
                  target: '.form-panel',
                  text: 'Chargement des données...',
                })
                this.$api
                  .put(`/contact/${this.contactData.id}`, data)
                  .then((response) => {
                    const apiResponse = response.data as ApiResponse

                    this.contactData = apiResponse.data
                    this.$notify({
                      type: 'success',
                      title: 'Succès',
                      message: 'Contact modifié avec succès !',
                    })
                    this.$emit('contactUpdated', apiResponse.data)
                  })
                  .catch((error) => {
                    if (error.response) {
                      const apiResponse = error.response.data as ApiResponse

                      this.$notify({
                        type: 'error',
                        title: 'Erreur',
                        message:
                          apiResponse.message ??
                          'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                      })
                    }
                  })
                  .finally(() => {
                    this.busy = false
                    this.changeDetected = false
                    loading.close()
                    this.addressSearch = ''
                    this.billingAddressSearch = ''
                  })
              }
            }
            this.busy = false
          })
      }
    },
    loadAddress(value: string, callback: CallableFunction) {
      this.$api
        .post('/contact/load-address', {
          search: value,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.addresses = apiResponse.data
          callback(this.addresses)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
          })
        })
    },
    selectAddress(type: string, address: Address) {
      if (type === 'both') {
        this.addressSearch = address.label
        this.contactData.address = address.street
        this.contactData.city = address.city
        this.contactData.postalCode = address.postcode
        this.contactData.country = 'France'
      } else if (type === 'billing') {
        this.billingAddressSearch = address.label
        this.contactData.billingAddress = address.street
        this.contactData.billingCity = address.city
        this.contactData.billingPostalCode = address.postcode
        this.contactData.billingCountry = 'France'
      }
    },
    countriesSearch(queryString: string, cb: CallableFunction) {
      const results = queryString ? this.countries.filter(this.createFilter(queryString)) : this.countries
      // call callback function to return suggestions
      cb(results)
    },
    createFilter(queryString: string) {
      return (country: string) => {
        return country.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    chooseCountry(type: string, country: string) {
      if (type === 'billing') {
        this.contactData.billingCountry = country
      } else {
        this.contactData.country = country
      }
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
      } else {
        return '-'
      }
    },
    timeStampLabel(str1: string, str2: string): string {
      const date = Date.parse(str1)
      let text = '-'
      if (!isNaN(date)) {
        text =
          new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date) + ' par ' + str2
      }
      return text
    },
    addNewEmail(additionalEmail: string) {
      if (this.contactData.additionalEmails === null) {
        this.contactData = {
          ...this.contactData,
          additionalEmails: [],
        }
      }
      this.contactData?.additionalEmails?.push(additionalEmail)
      this.additionalEmail = ''
    },
    removeAdditionalEmail(removedEmail: string) {
      this.contactData?.additionalEmails?.splice(this.contactData?.additionalEmails.indexOf(removedEmail), 1)
    },
  },
})
